// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-header {
  font-size: 24px !important;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}

.login-header p {
  margin: 0;
  padding: 0;
}

.login-form {
  padding: 20px 0;
  border-bottom: 1px solid #ccc;
}

.login-form-control {
  margin-bottom: 15px;
}

.login-form input {
  outline: none;
  border: 1px solid #ccc;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
}

.login-form input[type="submit"] {
  outline: none;
  width: 100%;
  background-color: rgb(91, 192, 222);
  padding: 10px 16px;
  border: none;
  color: white;
  border-radius: 6px;
  font-size: 18px;
}

.login-form input[type="submit"]:disabled {
  opacity: 0.6;
}

.login-form-control p {
  color: red;
  font-size: 14px;
}

.login-footer {
  padding: 20px 0;
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/Pages/login.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,oBAAoB;EACpB,6BAA6B;AAC/B;;AAEA;EACE,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,eAAe;EACf,6BAA6B;AAC/B;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;EACf,WAAW;AACb;;AAEA;EACE,aAAa;EACb,WAAW;EACX,mCAAmC;EACnC,kBAAkB;EAClB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,UAAU;EACV,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".login-header {\r\n  font-size: 24px !important;\r\n  padding-bottom: 10px;\r\n  border-bottom: 1px solid #ccc;\r\n}\r\n\r\n.login-header p {\r\n  margin: 0;\r\n  padding: 0;\r\n}\r\n\r\n.login-form {\r\n  padding: 20px 0;\r\n  border-bottom: 1px solid #ccc;\r\n}\r\n\r\n.login-form-control {\r\n  margin-bottom: 15px;\r\n}\r\n\r\n.login-form input {\r\n  outline: none;\r\n  border: 1px solid #ccc;\r\n  padding: 6px 12px;\r\n  border-radius: 4px;\r\n  font-size: 14px;\r\n  width: 100%;\r\n}\r\n\r\n.login-form input[type=\"submit\"] {\r\n  outline: none;\r\n  width: 100%;\r\n  background-color: rgb(91, 192, 222);\r\n  padding: 10px 16px;\r\n  border: none;\r\n  color: white;\r\n  border-radius: 6px;\r\n  font-size: 18px;\r\n}\r\n\r\n.login-form input[type=\"submit\"]:disabled {\r\n  opacity: 0.6;\r\n}\r\n\r\n.login-form-control p {\r\n  color: red;\r\n  font-size: 14px;\r\n}\r\n\r\n.login-footer {\r\n  padding: 20px 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
