// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-navbar {
  padding-left: 0;
  width: -moz-fit-content;
  width: fit-content;
}

.custom-navbar-nav {
  margin: 0 auto;
}

.logout-button {
  border: none;
  outline: none;
  background-color: transparent;
  color: rgba(0, 0, 0, 0.65);
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/components/UI/customNavbar.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,uBAAkB;EAAlB,kBAAkB;AACpB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,6BAA6B;EAC7B,0BAA0B;AAC5B","sourcesContent":[".custom-navbar {\r\n  padding-left: 0;\r\n  width: fit-content;\r\n}\r\n\r\n.custom-navbar-nav {\r\n  margin: 0 auto;\r\n}\r\n\r\n.logout-button {\r\n  border: none;\r\n  outline: none;\r\n  background-color: transparent;\r\n  color: rgba(0, 0, 0, 0.65);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
