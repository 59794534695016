import React, { useContext, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../assets/styles/Pages/login.css";
import { useNavigate } from "react-router-dom";
import { BOOKING_PAGE } from "../constants/pageConstants";
import axios from "axios";
import { apiUrl } from "../config/apiUrl";
import { UIContext } from "../context/ui-context";

const Login = () => {
  const [userName, setUserName] = useState("");
  const [userNameMessage, setUserNameMessage] = useState(null);
  const [password, setPassword] = useState("");
  const [passwordMessage, setPasswordMessage] = useState(null);
  const [serverError, setServerError] = useState(null);
  const { setToken } = useContext(UIContext);
  const navigate = useNavigate();
console.log("Test22");
  const submitFormHandler = async (e) => {
    e.preventDefault();

    setUserNameMessage(null);
    setPasswordMessage(null);
    setServerError(null);

    if (userName.trim().length === 0) {
      setUserNameMessage("Unesite korisničko ime");
      return;
    }

    if (userName.trim().length === 0) {
      setPasswordMessage("Niste uneli lozinku");
      return;
    }

    try {
      const response = await axios.post(`${apiUrl}/auth/login`, {
        email: userName,
        password: password,
      });

      setToken(response.data);
      navigate(BOOKING_PAGE);
    } catch (e) {
      setServerError("Invalid credentials!");
    }

    setUserName("");
    setPassword("");
  };

  return (
    <Container>
      <Row>
        <Col lg="4" md="6" xs="12">
          <div className="login-header">
            <p>Prijava</p>
          </div>
          <div className="login-form">
            <form onSubmit={submitFormHandler}>
              <div className="login-form-control">
                <input
                  placeholder="Korisničko ime"
                  type="text"
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                />
                {userNameMessage && <p>{userNameMessage}</p>}
              </div>
              <div className="login-form-control">
                <input
                  placeholder="Lozinka"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {passwordMessage && <p>{passwordMessage}</p>}
              </div>
              <div className="login-form-control">
                <input
                  disabled={
                    userName.trim().length === 0 || password.trim().length === 0
                  }
                  type="submit"
                  value="Prijava"
                />
                <div className="w-100 d-flex justify-content-center">
                  {serverError && <p>{serverError}</p>}
                </div>
              </div>
            </form>
          </div>
          <div className="login-footer">
            <p>Booking v0.1.5.0 &copy; 2024</p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
