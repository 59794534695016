import { createContext, useEffect, useRef, useState } from "react";

export const UIContext = createContext();

export const UIContextProvider = (props) => {
  const [token, setToken] = useState(JSON.parse(localStorage.getItem("token")));

  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) {
      didMount.current = false;
      return;
    }

    localStorage.setItem("token", JSON.stringify(token));
  }, [token]);

  return (
    <UIContext.Provider value={{ token, setToken }}>
      {props.children}
    </UIContext.Provider>
  );
};
