import React, { useContext } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Booking from "./pages/Booking";
import BookingList from "./pages/BookingList";
import Login from "./pages/Login";
import {
  BOOKING_PAGE,
  BOOKING_LIST_PAGE,
  LOGIN_PAGE,
  WRONG_PATH,
} from "./constants/pageConstants";
import "./App.css";
import CustomNavbar from "./components/UI/CustomNavbar";
import { UIContext } from "./context/ui-context";
import BookingEdit from "./pages/BookingEdit";
import AxiosConfig from "./AxiosConfig";
import "bootstrap/dist/css/bootstrap.min.css";

const App = () => {
  const { token } = useContext(UIContext);

  return (
    <>
      <AxiosConfig />
      {token && <CustomNavbar />}
      <div className="navbar-offset">
        {token ? (
          <Routes>
            <Route path={BOOKING_PAGE} element={<Booking />} />
            <Route path="/booking-details/:id" element={<BookingEdit />} />
            <Route path={BOOKING_LIST_PAGE} element={<BookingList />} />
            <Route path={WRONG_PATH} element={<Navigate to={BOOKING_PAGE} />} />
          </Routes>
        ) : (
          <Routes>
            <Route path={LOGIN_PAGE} element={<Login />} />
            <Route
              path={WRONG_PATH}
              element={<Navigate replace to={LOGIN_PAGE} />}
            />
          </Routes>
        )}
      </div>
    </>
  );
};

export default App;
