import React from "react";
import { Button, Modal } from "react-bootstrap";

const ConfirmationDialog = ({ text, opened, setIsOpened, callback }) => {
  const handleClose = () => {
    setIsOpened(false);
  };

  return (
    <Modal centered show={opened} onHide={handleClose}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>{text}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Ne
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            callback();
            handleClose();
          }}
        >
          Da
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationDialog;
