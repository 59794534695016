import React from "react";
import axios from "axios";
import { apiUrl } from "../../../config/apiUrl";
import { Button } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  directions,
  directionsInverse,
  travelLineoptions,
} from "../../../constants/bookingConstants";
import "../../../assets/styles/components/features/bookingList/BookingListFilters.css";

const BookingListFilters = ({ bookings, setBookings, filters, setFilters }) => {
  const canceledBookingsCnt = bookings.filter(
    (booking) => booking.status === 2
  ).length;

  const handleFetchData = async () => {
    try {
      const res = await axios.get(`${apiUrl}/booking`, {
        params: filters,
      });

      setBookings(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDateChange = (date, fieldName) => {
    setFilters((prev) => ({
      ...prev,
      [fieldName]: date,
    }));
  };

  return (
    <div className="bl-filters-cont">
      <div className="bl-filters-row1">
        <div className="bl-filters-row1-left-side">
          <div>
            <ReactDatePicker
              dateFormat="EEEE d MMMM yyyy"
              selected={filters.travelDate}
              onChange={(date) => handleDateChange(date, "travelDate")}
            />
          </div>
          <select
            name="travelRoute"
            value={filters.travelRoute}
            onChange={handleInputChange}
          >
            {travelLineoptions.map((option, index) => (
              <option key={index} value={index}>
                {option}
              </option>
            ))}
          </select>
          <select
            name="directionInverse"
            value={filters.directionInverse}
            onChange={handleInputChange}
          >
            <option value={false}>{directions[filters.travelRoute]}</option>
            <option value={true}>
              {directionsInverse[filters.travelRoute]}
            </option>
          </select>
          <div>
            <input
              type="checkbox"
              name="isEveningDeparture"
              onChange={(e) =>
                setFilters((prev) => ({
                  ...prev,
                  isEveningDeparture: e.target.checked ? true : false,
                }))
              }
            />
            <label className="ms-2">Večernji polazak</label>
          </div>
        </div>
        <div className="d-flex">
          <input
            name="id"
            type="text"
            placeholder="Broj booking-a"
            onChange={handleInputChange}
          />
          <Button className="ms-3" onClick={handleFetchData}>
            Prikaži
          </Button>
        </div>
      </div>
      <div className="bl-filters-row2">
        <div className="bl-filters-row2-left-side">
          <div className="bl-filters-row2-col">
            <strong>Datum kreiranja od</strong>
            <div>
              <ReactDatePicker
                placeholderText="Datum kreiranja od"
                dateFormat="dd/MM/yyyy"
                selected={filters.creationDateFrom}
                onChange={(date) => handleDateChange(date, "creationDateFrom")}
                maxDate={filters.creationDateTo}
              />
            </div>
          </div>
          <div className="bl-filters-row2-col">
            <strong>Datum kreiranja do</strong>
            <div>
              <ReactDatePicker
                placeholderText="Datum kreiranja do"
                dateFormat="dd/MM/yyyy"
                selected={filters.creationDateTo}
                onChange={(date) => handleDateChange(date, "creationDateTo")}
                minDate={filters.creationDateFrom}
              />
            </div>
          </div>
        </div>
        <div className="bl-filters-row2-right-side">
          <div className="bl-filters-row2-col align-items-center">
            <strong>Ukupan broj rezervacija</strong>
            <p style={{ color: "red" }}>{bookings.length}</p>
          </div>
          <div className="bl-filters-row2-col align-items-center">
            <strong>Otkazanih rezervacija</strong>
            <p style={{ color: "red" }}>{canceledBookingsCnt}</p>
          </div>
          <div className="bl-filters-row2-col align-items-center">
            <strong>Neotkazanih rezervacija</strong>
            <p style={{ color: "red" }}>
              {bookings.length - canceledBookingsCnt}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingListFilters;
