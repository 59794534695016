import { Table } from "react-bootstrap";
import axios from "axios";
import { useState } from "react";
import { apiUrl } from "../../../config/apiUrl.js";
import {
  paymentType,
  statusOptions,
} from "../../../constants/bookingConstants.js";
import "../../../assets/styles/components/features/bookingList/BookingListTable.css";

const BookingListTable = ({ bookings, setBookings }) => {
  const [loading, setLoading] = useState(false);

  const handleStatusChange = async (index, event) => {
    try {
      setLoading(true);
      const newStatus = event.target.value;
      await axios.patch(
        `${apiUrl}/booking/${bookings[index].bookingId}/${bookings[index].passengerId}/${bookings[index].directionInverse}/${newStatus}`
      );

      setBookings((prevBookings) => {
        const newBookings = [...prevBookings];
        newBookings[index].status = newStatus;
        return newBookings;
      });
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getColor = (status) => {
    if (!status) return;
    status = status.toString();

    if (status === "0") {
      return "white";
    }
    if (status === "1") {
      return "#9cec94";
    }
    if (status === "2") {
      return "#fba37b";
    }
  };

  const getColorForBooking = (bookingId) => {
    const red = ((bookingId * 100) % 256).toString(16).padStart(2, "0");
    const green = ((bookingId * 50) % 256).toString(16).padStart(2, "0");
    const blue = ((bookingId * 25) % 256).toString(16).padStart(2, "0");
    const alphaHex = Math.floor(0.12 * 255)
      .toString(16)
      .padStart(2, "0");

    return `#${red}${green}${blue}${alphaHex}`;
  };

  return (
    <Table>
      <thead>
        <tr>
          <th>Booking ID</th>
          <th>Ime i prezime</th>
          <th>Telefon</th>
          <th>Početna adresa</th>
          <th>Grad</th>
          <th>Plaćanje</th>
          <th>Krajnja Adresa</th>
          <th>Grad</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {bookings.map((data, index) => (
          <tr key={index}>
            <td
              style={{
                backgroundColor: getColorForBooking(data.bookingId),
              }}
            >
              <a
                href={`/booking-details/${data.bookingId}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                #{data.bookingId}
              </a>
            </td>
            <td style={{ backgroundColor: getColorForBooking(data.bookingId) }}>
              {data.passengerName}
            </td>
            <td style={{ backgroundColor: getColorForBooking(data.bookingId) }}>
              {data.phone}
            </td>
            <td style={{ backgroundColor: getColorForBooking(data.bookingId) }}>
              {data.departureAddress}
            </td>
            <td style={{ backgroundColor: getColorForBooking(data.bookingId) }}>
              {data.departureCity}
            </td>
            <td style={{ backgroundColor: getColorForBooking(data.bookingId) }}>
              {`${data.travelType === 0 ? "OW" : "RT"} ${
                paymentType[data.paymentType]
              }`}
            </td>
            <td style={{ backgroundColor: getColorForBooking(data.bookingId) }}>
              {data.destinationAddress}
            </td>
            <td style={{ backgroundColor: getColorForBooking(data.bookingId) }}>
              {data.destinationCity}
            </td>
            <td style={{ backgroundColor: getColorForBooking(data.bookingId) }}>
              <select
                disabled={loading}
                value={data.status}
                onChange={(event) => handleStatusChange(index, event)}
                style={{
                  backgroundColor: getColor(data.status),
                }}
              >
                {statusOptions.map((option, index) => (
                  <option key={index} value={index}>
                    {option}
                  </option>
                ))}
              </select>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default BookingListTable;
