// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bl-filters-cont select {
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    padding: 6px 12px;
}

.bl-filters-cont strong {
    font-size: smaller;
}

.bl-filters-cont p {
    font-size: large;
    margin: 0;
    padding: 0;
}

.bl-filters-cont { 
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.bl-filters-row1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.bl-filters-row1-left-side {
    display: flex;
    align-items: center;
    gap: 20px;
}

.bl-filters-row2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}

.bl-filters-row2-col {
    display: flex;
    flex-direction: column;
    gap: 10px;

    margin-top: 20px;
}

.bl-filters-row2-left-side {
    display: flex;
    align-items: center;
    gap: 20px;
}

.bl-filters-row2-right-side {
    display: flex;
    gap: 20px;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/components/features/bookingList/BookingListFilters.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;IACb,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,SAAS;IACT,UAAU;AACd;;AAEA;IACI,WAAW;IACX,iBAAiB;IACjB,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,SAAS;AACb;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;;IAET,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,aAAa;IACb,SAAS;AACb","sourcesContent":[".bl-filters-cont select {\r\n    border: 1px solid #ccc;\r\n    border-radius: 4px;\r\n    outline: none;\r\n    padding: 6px 12px;\r\n}\r\n\r\n.bl-filters-cont strong {\r\n    font-size: smaller;\r\n}\r\n\r\n.bl-filters-cont p {\r\n    font-size: large;\r\n    margin: 0;\r\n    padding: 0;\r\n}\r\n\r\n.bl-filters-cont { \r\n    width: 100%;\r\n    padding-top: 1rem;\r\n    padding-bottom: 1rem;\r\n}\r\n\r\n.bl-filters-row1 {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n}\r\n\r\n.bl-filters-row1-left-side {\r\n    display: flex;\r\n    align-items: center;\r\n    gap: 20px;\r\n}\r\n\r\n.bl-filters-row2 {\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: space-between;\r\n    gap: 20px;\r\n}\r\n\r\n.bl-filters-row2-col {\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 10px;\r\n\r\n    margin-top: 20px;\r\n}\r\n\r\n.bl-filters-row2-left-side {\r\n    display: flex;\r\n    align-items: center;\r\n    gap: 20px;\r\n}\r\n\r\n.bl-filters-row2-right-side {\r\n    display: flex;\r\n    gap: 20px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
