import axios from "axios";
import React, { useState } from "react";
import { apiUrl } from "../config/apiUrl";
import { Button, Container } from "react-bootstrap";
import BookingListFilters from "../components/features/bookingList/BookingListFilters";
import BookingListTable from "../components/features/bookingList/BookingListTable";

const BookingList = () => {
  const [bookings, setBookings] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  const handleExcelDownload = async () => {
    try {
      const res = await axios.get(`${apiUrl}/booking/export`, {
        params: filters,
        responseType: "blob",
      });

      const blob = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `Booking List ${new Date().toLocaleDateString()}.xlsx`
      );

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Container>
      <div className="booking-header">
        <p>Booking lista</p>
      </div>
      <BookingListFilters
        bookings={bookings}
        setBookings={setBookings}
        filters={filters}
        setFilters={setFilters}
      />
      <BookingListTable bookings={bookings} setBookings={setBookings} />
      {bookings.length > 0 && (
        <Button
          variant="secondary"
          className="mb-5"
          onClick={handleExcelDownload}
        >
          Excel download
        </Button>
      )}
    </Container>
  );
};

const initialFilters = {
  id: null,
  directionInverse: false,
  travelRoute: 0,
  travelDate: new Date().toISOString().split("T")[0],
  creationDateFrom: null,
  creationDateTo: null,
  isEveningDeparture: false,
};

export default BookingList;
