import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "../../assets/styles/components/UI/customNavbar.css";
import { Link, useNavigate } from "react-router-dom";
import {
  BOOKING_LIST_PAGE,
  BOOKING_PAGE,
  LOGIN_PAGE,
} from "../../constants/pageConstants";
import { useContext } from "react";
import { UIContext } from "../../context/ui-context";

function CustomNavbar() {
  const { token, setToken } = useContext(UIContext);
  const navigate = useNavigate();

  const logoutHandler = () => {
    setToken(null);
    navigate(LOGIN_PAGE);
  };

  return (
    <Navbar expand="lg" className="bg-body-tertiary fixed-top">
      <Container fluid>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll" className="custom-navbar">
          <Nav
            className="me-auto my-2 my-lg-0 custom-navbar-nav"
            style={{ maxHeight: "100px" }}
            navbarScroll
          >
            {token && (
              <Nav.Link as={Link} to={BOOKING_PAGE}>
                Booking
              </Nav.Link>
            )}
            {token && (
              <Nav.Link as={Link} to={BOOKING_LIST_PAGE}>
                Booking Lista
              </Nav.Link>
            )}
            {!token && (
              <Nav.Link as={Link} to={LOGIN_PAGE}>
                Login
              </Nav.Link>
            )}
            {token && (
              <button onClick={logoutHandler} className="logout-button">
                Odjavi se
              </button>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default CustomNavbar;
