import React, { useState } from "react";
import { Col, Container, Row, Toast, ToastContainer } from "react-bootstrap";
import "../assets/styles/Pages/booking.css";
import { LuRefreshCw } from "react-icons/lu";
import axios from "axios";
import { apiUrl } from "../config/apiUrl";
import {
  destinations,
  directionOptions,
  directions,
  directionsInverse,
  paymentMethodOptions,
  subAgentOptions,
  timeOptions,
} from "../constants/bookingConstants";
import ReactDatePicker from "react-datepicker";
import ConfirmationDialog from "../components/UI/ConfirmationDialog";

const Booking = () => {
  const [passenger, setPassenger] = useState("");
  const [passengers, setPassengers] = useState([]);
  const [passengersMessage, setPassengersMessage] = useState(null);
  const [line, setLine] = useState("0");
  const [tripType, setTripType] = useState("0");
  const [subAgent, setSubAgent] = useState(0);
  const [tripDate, setTripDate] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [eveningDeparture, setEveningDeparture] = useState(false);
  const [departureTime, setDepartureTime] = useState(timeOptions[0]);
  const [arrivalTime, setArrivalTime] = useState(timeOptions[0]);
  const [paymentMethod, setPaymentMethod] = useState("1");
  const [note, setNote] = useState("");
  const [startAddress, setStartAddress] = useState("");
  const [endAddress, setEndAddress] = useState("");
  const [startCity, setStartCity] = useState("");
  const [endCity, setEndCity] = useState("");
  const [startAddressNote, setStartAddressNote] = useState("");
  const [endAddressNote, setEndAddressNote] = useState("");
  const [addressMessage, setAddressMessage] = useState(null);
  const [rtTripDate, setRtTripDate] = useState(null);
  const [rtEveningDeparture, setRtEveningDeparture] = useState(false);
  const [rtDepartureTime, setRtDepartureTime] = useState(timeOptions[0]);
  const [rtArrivalTime, setRtArrivalTime] = useState(timeOptions[0]);
  const [rtPaymentMethod, setRtPaymentMethod] = useState("0");
  const [rtNote, setRtNote] = useState("");
  const [rtStartAddress, setRtStartAddress] = useState("");
  const [rtEndAddress, setRtEndAddress] = useState("");
  const [rtStartCity, setRtStartCity] = useState("");
  const [rtEndCity, setRtEndCity] = useState("");
  const [rtStartAddressNote, setRtStartAddressNote] = useState("");
  const [rtEndAddressNote, setRtEndAddressNote] = useState("");
  const [rtAddressMessage, setRtAddressMessage] = useState(null);
  const [serverError, setServerError] = useState(null);
  const [showToast, setShowToas] = useState(false);
  const [directionInverse, setDirectionInverse] = useState(false);
  const [cdOpened, setIsCdOpened] = useState(false);

  const addPassengerHandler = () => {
    const passengerData = passenger.split(" ");
    if (passengerData.length === 0) {
      alert("Unesite ime, prezime i broj telefona");
      return;
    } else if (passengerData.length === 1) {
      alert("Unesite prezime i broj telefona");
      return;
    } else if (passengerData.length === 2) {
      alert("Unesite broj telefona");
      return;
    } else if (passengerData.length > 3) {
      alert("Uneli ste previše podataka");
      return;
    }

    setPassengers([
      ...passengers,
      {
        firstName: passengerData[0],
        lastName: passengerData[1],
        phone: passengerData[2],
      },
    ]);

    setPassenger("");
    setPassengersMessage(null);
  };

  const editPassengerHandler = (sentIndex) => {
    const selectedPassenger = passengers.filter(
      (p, index) => index === sentIndex
    );
    setPassenger(
      `${selectedPassenger[0].firstName} ${selectedPassenger[0].lastName} ${selectedPassenger[0].phone}`
    );

    deletePassengerHandler(sentIndex);
  };

  const deletePassengerHandler = (index) => {
    const copyOfPassengers = [...passengers];
    copyOfPassengers.splice(index, 1);
    setPassengers([...copyOfPassengers]);
  };

  // const addCompanyHandler = () => {
  //   setCompany("");
  // };

  const onSubmitFormHandler = async () => {
    setServerError(null);
    setPassengersMessage(null);
    setAddressMessage(null);
    setRtAddressMessage(null);

    if (passengers.length === 0) {
      setPassengersMessage("You must add at least one passenger!");
      return;
    }

    if (
      startAddress.trim().length === 0 ||
      startCity.trim().length === 0 ||
      endAddress.trim().length === 0 ||
      endCity.trim().length === 0
    ) {
      setAddressMessage("You must fill all address fields!");
      return;
    }

    if (
      tripType === "1" &&
      (rtStartAddress.trim().length === 0 ||
        rtStartCity.trim().length === 0 ||
        rtEndAddress.trim().length === 0 ||
        rtEndCity.trim().length === 0)
    ) {
      setRtAddressMessage("You must fill all return fields!");
      return;
    }

    try {
      if (tripType === "0") {
        await axios.post(`${apiUrl}/booking`, {
          travelRoute: Number(line),
          travelType: Number(tripType),
          subagent: Number(subAgent),
          directionInverse,
          directionOne: {
            travelDate: tripDate,
            departureTime,
            arrivalTime,
            isEveningDeparture: eveningDeparture,
            paymentType: Number(paymentMethod),
            note,
            departureAddress: startAddress,
            departureCity: startCity,
            departureAddressNote: startAddressNote,
            destinationAddress: endAddress,
            destinationCity: endCity,
            destinationAddressNote: endAddressNote,
          },
          passengers,
        });
      } else {
        await axios.post(`${apiUrl}/booking`, {
          travelRoute: Number(line),
          travelType: Number(tripType),
          subagent: Number(subAgent),
          directionInverse,
          directionOne: {
            travelDate: tripDate,
            departureTime,
            arrivalTime,
            isEveningDeparture: eveningDeparture,
            paymentType: Number(paymentMethod),
            note,
            departureAddress: startAddress,
            departureCity: startCity,
            departureAddressNote: startAddressNote,
            destinationAddress: endAddress,
            destinationCity: endCity,
            destinationAddressNote: endAddressNote,
          },
          directionTwo: {
            travelDate: rtTripDate,
            departureTime: rtDepartureTime,
            arrivalTime: rtArrivalTime,
            isEveningDeparture: rtEveningDeparture,
            paymentType: Number(rtPaymentMethod),
            note: rtNote,
            departureAddress: rtStartAddress,
            departureCity: rtStartCity,
            departureAddressNote: rtStartAddressNote,
            destinationAddress: rtEndAddress,
            destinationCity: rtEndCity,
            destinationAddressNote: rtEndAddressNote,
          },
          passengers,
        });
      }

      setPassenger("");
      setPassengers([]);
      setLine("0");
      setSubAgent("0");
      setTripDate(new Date().toISOString().slice(0, 10));
      setEveningDeparture(false);
      setDepartureTime("0");
      setArrivalTime("0");
      setPaymentMethod("0");
      setNote("");
      setStartAddress("");
      setStartCity("");
      setStartAddressNote("");
      setEndAddress("");
      setEndCity("");
      setEndAddressNote("");
      setRtTripDate(null);
      setRtEveningDeparture(false);
      setRtDepartureTime("0");
      setRtArrivalTime("0");
      setRtPaymentMethod("0");
      setRtNote("");
      setRtStartAddress("");
      setRtStartCity("");
      setRtStartAddressNote("");
      setRtEndAddress("");
      setRtEndCity("");
      setRtEndAddressNote("");
      setShowToas(true);
    } catch (e) {
      console.log(e);
      setServerError("Something went wrong!");
    }
  };

  return (
    <>
      <ConfirmationDialog
        opened={cdOpened}
        setIsOpened={setIsCdOpened}
        text={
          "Da li ste sigurni da želite da sačuvate booking sa unetim podacima?"
        }
        callback={onSubmitFormHandler}
      />
      <ToastContainer position="top-end" className="position-fixed">
        <Toast
          show={showToast}
          onClose={() => setShowToas(false)}
          delay={4000}
          className="m-3"
          autohide
        >
          <Toast.Body style={{ backgroundColor: "#79b474", color: "white" }}>
            Booking uspešno sačuvan!
          </Toast.Body>
        </Toast>
      </ToastContainer>
      <Container className="booking">
        <div>
          <div className="booking-header">
            <p>Booking</p>
          </div>
          <div className="booking-section">
            <div>
              <div className="booking-section-header">
                <p>Putnici</p>
              </div>
              <div className="booking-section-content">
                <Row className="align-items-center mb-3">
                  <Col lg="5">
                    <input
                      type="text"
                      className="w-100"
                      placeholder="Ime Prezime Telefon"
                      value={passenger}
                      onChange={(e) => setPassenger(e.target.value)}
                    />
                  </Col>
                  <Col lg="7" className="passengers-actions">
                    <button
                      style={{ borderRadius: "4px" }}
                      onClick={addPassengerHandler}
                    >
                      Dodaj klijenta
                    </button>
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <table className="passengers-table">
                      <thead style={{ borderBottom: "2px solid #ccc" }}>
                        <tr>
                          <th>Ime</th>
                          <th>Prezime</th>
                          <th>Napomena</th>
                          <th>Telefon</th>
                          <th
                            style={{ width: "1%", whiteSpace: "nowrap" }}
                          ></th>
                          <th
                            style={{ width: "1%", whiteSpace: "nowrap" }}
                          ></th>
                        </tr>
                      </thead>
                      <tbody>
                        {passengers &&
                          passengers.map((p, index) => (
                            <tr
                              key={index}
                              className="passengers-table-row"
                              style={{
                                borderTop: `${
                                  index > 0 ? "1px solid #ccc" : ""
                                }`,
                              }}
                            >
                              <td>{p.firstName}</td>
                              <td>{p.lastName}</td>
                              <td></td>
                              <td>{p.phone}</td>
                              <td style={{ width: "1%", whiteSpace: "nowrap" }}>
                                <button
                                  style={{ marginRight: "5px" }}
                                  onClick={() => editPassengerHandler(index)}
                                >
                                  Izmeni podatke
                                </button>
                              </td>
                              <td style={{ width: "1%", whiteSpace: "nowrap" }}>
                                <button
                                  onClick={() => deletePassengerHandler(index)}
                                >
                                  Ukloni putnika
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </Col>
                </Row>
                {passengersMessage && (
                  <div className="my-2">
                    <p className="text-red">{passengersMessage}</p>
                  </div>
                )}
              </div>
            </div>

            {/* PODACI O PUTOVANJU */}
            <div>
              <div className="booking-section-header">
                <p>Podaci o putovanju</p>
              </div>
              <div className="booking-section-content">
                <Row className="align-items-center mb-3">
                  <Col lg="4">
                    <div className="trip-data-content d-flex align-items-center">
                      <p className="font-bold">Linija</p>
                      <select
                        className="ms-2"
                        value={line}
                        onChange={(e) => setLine(e.target.value)}
                      >
                        {destinations.map((dest, index) => (
                          <option key={index} value={index}>
                            {dest}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                  <Col lg="4">
                    <div className="trip-data-content d-flex align-items-center">
                      <p className="font-bold">Tip putovanja</p>
                      <select
                        className="ms-2"
                        value={tripType}
                        onChange={(e) => setTripType(e.target.value)}
                      >
                        {directionOptions.map((opt, index) => (
                          <option key={index} value={index}>
                            {opt}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                  <Col lg="4">
                    <div className="trip-data-content d-flex align-items-center">
                      <p className="font-bold">Subagent</p>
                      <select
                        className="ms-2"
                        value={subAgent}
                        onChange={(e) => setSubAgent(e.target.value)}
                      >
                        {subAgentOptions.map((opt, index) => (
                          <option key={index} value={index}>
                            {opt}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>

            {/* PODACI O PUTOVANJU */}
            <div>
              <div className="booking-section-header">
                <div className="d-flex align-items-center">
                  {directionInverse
                    ? directionsInverse[Number(line)]
                    : directions[Number(line)]}
                  <button
                    className="d-flex align-items-center justify-content-center ms-2 return-travel-btn"
                    onClick={() => {
                      setDirectionInverse((prev) => !prev);
                    }}
                  >
                    <LuRefreshCw />
                  </button>
                </div>
              </div>
              <Row>
                <Col lg="6">
                  <div className="booking-section-content">
                    <p className="travel-data-direction">
                      Pravac:{" "}
                      {directionInverse
                        ? directionsInverse[Number(line)]
                        : directions[Number(line)]}
                    </p>
                    <div className="d-flex align-items-center">
                      <p className="font-bold">Datum putovanja</p>
                      <div className="ms-1">
                        <ReactDatePicker
                          placeholderText="Datum putovanja"
                          dateFormat="dd/MM/yyyy"
                          selected={tripDate}
                          onChange={(date) => {
                            setTripDate(date);
                          }}
                        />
                      </div>
                      <div className="d-flex ms-1">
                        <input
                          type="checkbox"
                          checked={eveningDeparture}
                          onChange={(e) =>
                            setEveningDeparture((oldState) => !oldState)
                          }
                        />
                        <label
                          className="ms-1"
                          id="booking-section-content-checkbox-label"
                        >
                          Večernji polazak
                        </label>
                      </div>
                    </div>
                    <div className="d-flex align-items-center mt-2 travel-time">
                      <p className="font-bold me-1">Vreme polaska</p>
                      <select
                        value={departureTime}
                        onChange={(e) => setDepartureTime(e.target.value)}
                      >
                        {timeOptions.map((opt, index) => (
                          <option key={index} value={opt}>
                            {opt}
                          </option>
                        ))}
                      </select>
                      <p className="font-bold ms-1 me-1">Vreme dolaska</p>
                      <select
                        value={arrivalTime}
                        onChange={(e) => setArrivalTime(e.target.value)}
                      >
                        {timeOptions.map((opt, index) => (
                          <option key={index} value={opt}>
                            {opt}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="d-flex align-items-center mt-2 travel-time">
                      <p className="font-bold me-1">Način plaćanja</p>
                      <select
                        value={paymentMethod}
                        onChange={(e) => setPaymentMethod(e.target.value)}
                      >
                        {paymentMethodOptions.map((opt, index) => (
                          <option key={index} value={index}>
                            {opt}
                          </option>
                        ))}
                      </select>
                      <p className="font-bold ms-1 me-1">Napomena</p>
                      <textarea
                        cols={50}
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                      />
                    </div>
                    <Row className="mt-2 address-table">
                      <Col lg="6">
                        <table className="w-100">
                          <thead>
                            <tr>
                              <th>Adresa</th>
                              <th>Grad</th>
                              <th>Napomena za adresu</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              style={{ backgroundColor: "rgb(221, 221, 221)" }}
                            >
                              <td>
                                <input
                                  type="text"
                                  placeholder="Početna adresa"
                                  value={startAddress}
                                  onChange={(e) =>
                                    setStartAddress(e.target.value)
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  placeholder="Grad"
                                  value={startCity}
                                  onChange={(e) => setStartCity(e.target.value)}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  placeholder="Napomena za adresu"
                                  value={startAddressNote}
                                  onChange={(e) =>
                                    setStartAddressNote(e.target.value)
                                  }
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  type="text"
                                  placeholder="Krajnja adresa"
                                  value={endAddress}
                                  onChange={(e) =>
                                    setEndAddress(e.target.value)
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  placeholder="Grad"
                                  value={endCity}
                                  onChange={(e) => setEndCity(e.target.value)}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  placeholder="Napomena za adresu"
                                  value={endAddressNote}
                                  onChange={(e) =>
                                    setEndAddressNote(e.target.value)
                                  }
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </Col>
                    </Row>
                  </div>
                  {addressMessage && (
                    <div>
                      <p className="text-red">{addressMessage}</p>
                    </div>
                  )}
                </Col>
                {tripType === "1" && (
                  <Col lg="6">
                    <div className="booking-section-content">
                      <p className="travel-data-direction">
                        Pravac:{" "}
                        {directionInverse
                          ? directions[Number(line)]
                          : directionsInverse[Number(line)]}
                      </p>
                      <div className="d-flex align-items-center">
                        <p className="font-bold">Datum povratka</p>
                        <div className="ms-1">
                          <ReactDatePicker
                            placeholderText="Datum povratka"
                            dateFormat="dd/MM/yyyy"
                            selected={rtTripDate}
                            onChange={(date) =>
                              setRtTripDate(
                                new Date(
                                  Date.UTC(
                                    date.getFullYear(),
                                    date.getMonth(),
                                    date.getDate()
                                  )
                                )
                              )
                            }
                          />
                        </div>
                        <div className="d-flex ms-1">
                          <input
                            type="checkbox"
                            checked={rtEveningDeparture}
                            onChange={(e) =>
                              setRtEveningDeparture((oldState) => !oldState)
                            }
                          />
                          <label
                            className="ms-1"
                            id="booking-section-content-checkbox-label"
                          >
                            Večernji polazak
                          </label>
                        </div>
                      </div>
                      <div className="d-flex align-items-center mt-2 travel-time">
                        <p className="font-bold me-1">Vreme polaska</p>
                        <select
                          value={rtDepartureTime}
                          onChange={(e) => setRtDepartureTime(e.target.value)}
                        >
                          {timeOptions.map((opt, index) => (
                            <option key={index} value={opt}>
                              {opt}
                            </option>
                          ))}
                        </select>
                        <p className="font-bold ms-1 me-1">Vreme dolaska</p>
                        <select
                          value={rtArrivalTime}
                          onChange={(e) => setRtArrivalTime(e.target.value)}
                        >
                          {timeOptions.map((opt, index) => (
                            <option key={index} value={opt}>
                              {opt}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="d-flex align-items-center mt-2 travel-time">
                        <p className="font-bold me-1">Način plaćanja</p>
                        <select
                          value={rtPaymentMethod}
                          onChange={(e) => setRtPaymentMethod(e.target.value)}
                        >
                          {paymentMethodOptions.map((opt, index) => (
                            <option key={index} value={index}>
                              {opt}
                            </option>
                          ))}
                        </select>
                        <p className="font-bold ms-1 me-1">Napomena</p>
                        <textarea
                          cols={50}
                          value={rtNote}
                          onChange={(e) => setRtNote(e.target.value)}
                        />
                      </div>
                      <Row className="mt-2 address-table">
                        <Col lg="6">
                          <table className="w-100">
                            <thead>
                              <tr>
                                <th>Adresa</th>
                                <th>Grad</th>
                                <th>Napomena za adresu</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                style={{
                                  backgroundColor: "rgb(221, 221, 221)",
                                }}
                              >
                                <td>
                                  <input
                                    type="text"
                                    placeholder="Početna adresa"
                                    value={rtStartAddress}
                                    onChange={(e) =>
                                      setRtStartAddress(e.target.value)
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    placeholder="Grad"
                                    value={rtStartCity}
                                    onChange={(e) =>
                                      setRtStartCity(e.target.value)
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    placeholder="Napomena za adresu"
                                    value={rtStartAddressNote}
                                    onChange={(e) =>
                                      setRtStartAddressNote(e.target.value)
                                    }
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <input
                                    type="text"
                                    placeholder="Krajnja adresa"
                                    value={rtEndAddress}
                                    onChange={(e) =>
                                      setRtEndAddress(e.target.value)
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    placeholder="Grad"
                                    value={rtEndCity}
                                    onChange={(e) =>
                                      setRtEndCity(e.target.value)
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    placeholder="Napomena za adresu"
                                    value={rtEndAddressNote}
                                    onChange={(e) =>
                                      setRtEndAddressNote(e.target.value)
                                    }
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Col>
                      </Row>
                    </div>
                    {rtAddressMessage && (
                      <div>
                        <p className="text-red">{rtAddressMessage}</p>
                      </div>
                    )}
                  </Col>
                )}
              </Row>

              <div className="py-3 save-btn">
                <button onClick={() => setIsCdOpened(true)}>Sačuvaj</button>
              </div>
              {serverError && (
                <div className="d-flex justify-content-center pb-3">
                  <p className="text-red">{serverError}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Booking;
