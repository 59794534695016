import {
  Button,
  Col,
  Container,
  Row,
  Table,
  Toast,
  ToastContainer,
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import "../assets/styles/Pages/booking-edit.css";
import "../assets/styles/Pages/booking.css";
import {
  destinations,
  directionOptions,
  directions,
  directionsInverse,
  paymentMethodOptions,
  statusOptions,
  subAgentOptions,
  timeOptions,
} from "../constants/bookingConstants";
import { useEffect, useState } from "react";
import axios from "axios";
import { apiUrl } from "../config/apiUrl";
import ReactDatePicker from "react-datepicker";
import ConfirmationDialog from "../components/UI/ConfirmationDialog";

const BookingEdit = () => {
  const { id } = useParams();
  const [line, setLine] = useState("0");
  const [tripType, setTripType] = useState("0");
  const [subAgent, setSubAgent] = useState(0);
  const [tripDate, setTripDate] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [eveningDeparture, setEveningDeparture] = useState(false);
  const [departureTime, setDepartureTime] = useState("0");
  const [arrivalTime, setArrivalTime] = useState("0");
  const [paymentMethod, setPaymentMethod] = useState("0");
  const [note, setNote] = useState("");
  const [startAddress, setStartAddress] = useState("");
  const [endAddress, setEndAddress] = useState("");
  const [startCity, setStartCity] = useState("");
  const [endCity, setEndCity] = useState("");
  const [startAddressNote, setStartAddressNote] = useState("");
  const [endAddressNote, setEndAddressNote] = useState("");
  const [rtTripDate, setRtTripDate] = useState(null);
  const [rtEveningDeparture, setRtEveningDeparture] = useState(false);
  const [rtDepartureTime, setRtDepartureTime] = useState("0");
  const [rtArrivalTime, setRtArrivalTime] = useState("0");
  const [rtPaymentMethod, setRtPaymentMethod] = useState("0");
  const [rtNote, setRtNote] = useState("");
  const [rtStartAddress, setRtStartAddress] = useState("");
  const [rtEndAddress, setRtEndAddress] = useState("");
  const [rtStartCity, setRtStartCity] = useState("");
  const [rtEndCity, setRtEndCity] = useState("");
  const [rtStartAddressNote, setRtStartAddressNote] = useState("");
  const [rtEndAddressNote, setRtEndAddressNote] = useState("");
  const [directionInverse, setDirectionInverse] = useState(false);
  const [passengers, setPassengers] = useState([]);
  const [booking, setBooking] = useState();
  const [showToast, setShowToas] = useState(false);
  const [cdOpened, setIsCdOpened] = useState(false);
  //Brate ako ovo gledaš skreni pogled na drugu stranu pls.
  const [editPassenger, setEditPassenger] = useState(null);
  const [editPassengerStr, setEditPassengerStr] = useState("");
  const [passengersToUpdate, setPassengersToUpdate] = useState([]);

  useEffect(() => {
    const getDetails = async () => {
      try {
        let res = await axios.get(`${apiUrl}/booking/${id}`);
        res = res.data;

        setLine(res.travelRoute);
        setSubAgent(res.subagent);
        setTripType(res.travelType);
        setTripDate(res.travelDate.split("T")[0]);
        setEveningDeparture(res.isEveningDeparture);
        setDepartureTime(res.departureTime);
        setArrivalTime(res.arrivalTime);
        setPaymentMethod(res.paymentType);
        setNote(res.note);
        setStartAddress(res.departureAddress);
        setStartCity(res.departureCity);
        setStartAddressNote(res.departureAddressNote);
        setEndAddress(res.destinationAddress);
        setEndCity(res.destinationCity);
        setEndAddressNote(res.destinationAddressNote);
        setRtTripDate(
          res.travelDate2 !== null ? res.travelDate2.split("T")[0] : null
        );
        setRtEveningDeparture(res.isEveningDeparture2);
        setRtDepartureTime(res.departureTime2);
        setRtArrivalTime(res.arrivalTime2);
        setRtPaymentMethod(res.paymentType2);
        setRtNote(res.note2);
        setRtStartAddress(res.departureAddress2);
        setRtStartCity(res.departureCity2);
        setRtStartAddressNote(res.departureAddressNote2);
        setRtEndAddress(res.destinationAddress2);
        setRtEndCity(res.destinationCity2);
        setRtEndAddressNote(res.destinationAddressNote2);
        setPassengers(res.passengers);
        setDirectionInverse(res.directionInverse);
        setBooking(res);
      } catch (err) {
        console.log(err);
      }
    };

    getDetails();
  }, [id]);

  const onSubmitFormHandler = async () => {
    try {
      setShowToas(true);

      await axios.post(`${apiUrl}/booking/update`, {
        bookingId: booking.id,
        travelDate: tripDate,
        departureTime,
        arrivalTime,
        isEveningDeparture: eveningDeparture,
        paymentType: Number(paymentMethod),
        note,
        departureAddress: startAddress,
        departureCity: startCity,
        departureAddressNote: startAddressNote,
        destinationAddress: endAddress,
        destinationCity: endCity,
        destinationAddressNote: endAddressNote,
        travelDate2: rtTripDate,
        departureTime2: rtDepartureTime,
        arrivalTime2: rtArrivalTime,
        isEveningDeparture2: rtEveningDeparture,
        paymentType2: Number(rtPaymentMethod),
        note2: rtNote,
        departureAddress2: rtStartAddress,
        departureCity2: rtStartCity,
        departureAddressNote2: rtStartAddressNote,
        destinationAddress2: rtEndAddress,
        destinationCity2: rtEndCity,
        destinationAddressNote2: rtEndAddressNote,

        passengers: passengersToUpdate,
      });
    } catch (err) {
      console.log(err);
    }
  };
  const handleUpdatePassenger = (id) => {
    const passengerData = editPassengerStr.split(" ");
    if (passengerData.length === 0) {
      alert("Unesite ime, prezime i broj telefona");
      return;
    } else if (passengerData.length === 1) {
      alert("Unesite prezime i broj telefona");
      return;
    } else if (passengerData.length === 2) {
      alert("Unesite broj telefona");
      return;
    } else if (passengerData.length > 3) {
      alert("Uneli ste previše podataka");
      return;
    }
    const updatedPassenger = {
      id,
      firstName: passengerData[0],
      lastName: passengerData[1],
      phone: passengerData[2],
      status: editPassenger.status,
      status2: editPassenger.status2,
    };

    setPassengersToUpdate((prev) => {
      const index = prev.findIndex((passenger) => passenger.id === id);
      if (index !== -1) {
        const newPassengersToUpdate = [...prev];
        newPassengersToUpdate[index] = {
          ...newPassengersToUpdate[index],
          firstName: passengerData[0],
          lastName: passengerData[1],
          phone: passengerData[2],
        };
        return newPassengersToUpdate;
      } else {
        return [
          ...prev,
          {
            id: editPassenger.id,
            firstName: passengerData[0],
            lastName: passengerData[1],
            phone: passengerData[2],
          },
        ];
      }
    });

    setPassengers((prevPassengers) => {
      const index = prevPassengers.findIndex(
        (passenger) => passenger.id === id
      );

      const newPassengers = [...prevPassengers];
      newPassengers[index] = updatedPassenger;

      return newPassengers;
    });

    setEditPassenger(null);
    setEditPassengerStr("");
  };

  return (
    <>
      <ConfirmationDialog
        opened={cdOpened}
        setIsOpened={setIsCdOpened}
        text={"Da li ste sigurni da želite da sačuvate promene?"}
        callback={onSubmitFormHandler}
      />
      <ToastContainer position="top-end" className="position-fixed">
        <Toast
          show={showToast}
          onClose={() => setShowToas(false)}
          delay={4000}
          className="m-3"
          autohide
        >
          <Toast.Body style={{ backgroundColor: "#79b474", color: "white" }}>
            Booking uspešno sačuvan!
          </Toast.Body>
        </Toast>
      </ToastContainer>
      <Container className="booking">
        <div className="booking-header">
          <p>{`Izmena booking-a #${id}`}</p>
        </div>
        <div className="booking-section">
          <div className="booking-section-header">
            <p>Putnici</p>
          </div>
          {editPassenger && (
            <Row className="align-items-top mb-3 mt-3">
              <Col lg="5">
                <input
                  type="text"
                  className="w-100"
                  placeholder="Ime Prezime Telefon"
                  value={editPassengerStr}
                  onChange={(e) => setEditPassengerStr(e.target.value)}
                />
                <span style={{ fontSize: 12 }}>
                  Promene podataka putnika biće sačuvane prilikom čuvanja izmena
                  bookinga.
                </span>
              </Col>
              <Col lg="7" className="passengers-actions">
                <button
                  style={{ borderRadius: "4px" }}
                  onClick={() => handleUpdatePassenger(editPassenger.id)}
                >
                  Primeni izmene
                </button>
              </Col>
            </Row>
          )}
          <Table className="mt-2 mb-5">
            <thead>
              <tr>
                <th>Ime</th>
                <th>Prezime</th>
                <th>Telefon</th>
                <th>{`${
                  directionInverse
                    ? directionsInverse[Number(line)]
                    : directions[Number(line)]
                } Status`}</th>
                {tripType === 1 && (
                  <th>{`${
                    directionInverse
                      ? directions[Number(line)]
                      : directionsInverse[Number(line)]
                  } Status`}</th>
                )}
                <th></th>
              </tr>
            </thead>
            <tbody>
              {passengers.map((pass, index) => {
                return (
                  <tr key={index}>
                    <td>{pass.firstName}</td>
                    <td>{pass.lastName}</td>
                    <td>{pass.phone}</td>
                    <td>{statusOptions[pass.status]}</td>
                    {tripType === 1 && <td>{statusOptions[pass.status2]}</td>}
                    <td>
                      <Button
                        size="sm"
                        variant="outline-primary"
                        onClick={() => {
                          setEditPassenger(pass);
                          setEditPassengerStr(
                            `${pass.firstName} ${pass.lastName} ${pass.phone}`
                          );
                        }}
                      >
                        Izmeni
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <div>
            <div className="booking-section-header">
              <p>Podaci o putovanju</p>
            </div>
            <div className="booking-section-content ">
              <Row className="align-items-center mb-3">
                <Col lg="4">
                  <div className="trip-data-content d-flex align-items-center">
                    <p className="font-bold">Linija</p>
                    <select
                      className="ms-2"
                      value={line}
                      disabled
                      onChange={(e) => setLine(e.target.value)}
                    >
                      {destinations.map((dest, index) => (
                        <option key={index} value={index}>
                          {dest}
                        </option>
                      ))}
                    </select>
                  </div>
                </Col>
                <Col lg="4">
                  <div className="trip-data-content d-flex align-items-center">
                    <p className="font-bold">Tip putovanja</p>
                    <select
                      className="ms-2"
                      value={tripType}
                      disabled
                      onChange={(e) => setTripType(e.target.value)}
                    >
                      {directionOptions.map((opt, index) => (
                        <option key={index} value={index}>
                          {opt}
                        </option>
                      ))}
                    </select>
                  </div>
                </Col>
                <Col lg="4">
                  <div className="trip-data-content d-flex align-items-center">
                    <p className="font-bold">Subagent</p>
                    <select
                      className="ms-2"
                      value={subAgent}
                      disabled
                      onChange={(e) => setSubAgent(e.target.value)}
                    >
                      {subAgentOptions.map((opt, index) => (
                        <option key={index} value={index}>
                          {opt}
                        </option>
                      ))}
                    </select>
                  </div>
                </Col>
              </Row>
            </div>

            <div>
              <div className="booking-section-header">
                <div className="d-flex align-items-center">
                  {directionInverse
                    ? directionsInverse[Number(line)]
                    : directions[Number(line)]}
                </div>
              </div>
              <Row>
                <Col lg="6">
                  <div className="booking-section-content">
                    <p className="travel-data-direction">
                      Pravac:{" "}
                      {directionInverse
                        ? directionsInverse[Number(line)]
                        : directions[Number(line)]}
                    </p>
                    <div className="d-flex align-items-center">
                      <p className="font-bold">Datum putovanja</p>
                      <div className="ms-1">
                        <ReactDatePicker
                          placeholderText="Datum putovanja"
                          dateFormat="dd/MM/yyyy"
                          selected={tripDate}
                          onChange={(date) =>
                            setTripDate(date.toISOString().split("T")[0])
                          }
                        />
                      </div>
                      <div className="d-flex ms-1">
                        <input
                          type="checkbox"
                          checked={eveningDeparture}
                          onChange={(e) =>
                            setEveningDeparture((oldState) => !oldState)
                          }
                        />
                        <label
                          className="ms-1"
                          id="booking-section-content-checkbox-label"
                        >
                          Večernji polazak
                        </label>
                      </div>
                    </div>
                    <div className="d-flex align-items-center mt-2 travel-time">
                      <p className="font-bold me-1">Vreme polaska</p>
                      <select
                        value={departureTime}
                        onChange={(e) => setDepartureTime(e.target.value)}
                      >
                        {timeOptions.map((opt, index) => (
                          <option key={index} value={opt}>
                            {opt}
                          </option>
                        ))}
                      </select>
                      <p className="font-bold ms-1 me-1">Vreme dolaska</p>
                      <select
                        value={arrivalTime}
                        onChange={(e) => setArrivalTime(e.target.value)}
                      >
                        {timeOptions.map((opt, index) => (
                          <option key={index} value={opt}>
                            {opt}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="d-flex align-items-center mt-2 travel-time">
                      <p className="font-bold me-1">Način plaćanja</p>
                      <select
                        value={paymentMethod}
                        onChange={(e) => setPaymentMethod(e.target.value)}
                      >
                        {paymentMethodOptions.map((opt, index) => (
                          <option key={index} value={index}>
                            {opt}
                          </option>
                        ))}
                      </select>
                      <p className="font-bold ms-1 me-1">Napomena</p>
                      <textarea
                        cols={50}
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                      />
                    </div>
                    <Row className="mt-2 address-table">
                      <Col lg="6">
                        <table className="w-100">
                          <thead>
                            <tr>
                              <th>Adresa</th>
                              <th>Grad</th>
                              <th>Napomena za adresu</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              style={{ backgroundColor: "rgb(221, 221, 221)" }}
                            >
                              <td>
                                <input
                                  type="text"
                                  placeholder="Početna adresa"
                                  value={startAddress}
                                  onChange={(e) =>
                                    setStartAddress(e.target.value)
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  placeholder="Grad"
                                  value={startCity}
                                  onChange={(e) => setStartCity(e.target.value)}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  placeholder="Napomena za adresu"
                                  value={startAddressNote}
                                  onChange={(e) =>
                                    setStartAddressNote(e.target.value)
                                  }
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  type="text"
                                  placeholder="Krajnja adresa"
                                  value={endAddress}
                                  onChange={(e) =>
                                    setEndAddress(e.target.value)
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  placeholder="Grad"
                                  value={endCity}
                                  onChange={(e) => setEndCity(e.target.value)}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  placeholder="Napomena za adresu"
                                  value={endAddressNote}
                                  onChange={(e) =>
                                    setEndAddressNote(e.target.value)
                                  }
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </Col>
                    </Row>
                  </div>
                </Col>
                {tripType === 1 && (
                  <Col lg="6">
                    <div className="booking-section-content">
                      <p className="travel-data-direction">
                        Pravac:{" "}
                        {directionInverse
                          ? directions[Number(line)]
                          : directionsInverse[Number(line)]}
                      </p>
                      <div className="d-flex align-items-center">
                        <p className="font-bold">Datum povratka</p>
                        <div className="ms-1">
                          <ReactDatePicker
                            placeholderText="Datum povratka"
                            dateFormat="dd/MM/yyyy"
                            selected={rtTripDate}
                            onChange={(date) =>
                              setRtTripDate(
                                new Date(
                                  Date.UTC(
                                    date.getFullYear(),
                                    date.getMonth(),
                                    date.getDate()
                                  )
                                )
                              )
                            }
                          />
                        </div>
                        <div className="d-flex ms-1">
                          <input
                            type="checkbox"
                            checked={rtEveningDeparture}
                            onChange={(e) =>
                              setRtEveningDeparture((oldState) => !oldState)
                            }
                          />
                          <label
                            className="ms-1"
                            id="booking-section-content-checkbox-label"
                          >
                            Večernji polazak
                          </label>
                        </div>
                      </div>
                      <div className="d-flex align-items-center mt-2 travel-time">
                        <p className="font-bold me-1">Vreme polaska</p>
                        <select
                          value={rtDepartureTime}
                          onChange={(e) => setRtDepartureTime(e.target.value)}
                        >
                          {timeOptions.map((opt, index) => (
                            <option key={index} value={opt}>
                              {opt}
                            </option>
                          ))}
                        </select>
                        <p className="font-bold ms-1 me-1">Vreme dolaska</p>
                        <select
                          value={rtArrivalTime}
                          onChange={(e) => setRtArrivalTime(e.target.value)}
                        >
                          {timeOptions.map((opt, index) => (
                            <option key={index} value={opt}>
                              {opt}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="d-flex align-items-center mt-2 travel-time">
                        <p className="font-bold me-1">Način plaćanja</p>
                        <select
                          value={rtPaymentMethod}
                          onChange={(e) => setRtPaymentMethod(e.target.value)}
                        >
                          {paymentMethodOptions.map((opt, index) => (
                            <option key={index} value={index}>
                              {opt}
                            </option>
                          ))}
                        </select>
                        <p className="font-bold ms-1 me-1">Napomena</p>
                        <textarea
                          cols={50}
                          value={rtNote}
                          onChange={(e) => setRtNote(e.target.value)}
                        />
                      </div>
                      <Row className="mt-2 address-table">
                        <Col lg="6">
                          <table className="w-100">
                            <thead>
                              <tr>
                                <th>Adresa</th>
                                <th>Grad</th>
                                <th>Napomena za adresu</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                style={{
                                  backgroundColor: "rgb(221, 221, 221)",
                                }}
                              >
                                <td>
                                  <input
                                    type="text"
                                    placeholder="Početna adresa"
                                    value={rtStartAddress}
                                    onChange={(e) =>
                                      setRtStartAddress(e.target.value)
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    placeholder="Grad"
                                    value={rtStartCity}
                                    onChange={(e) =>
                                      setRtStartCity(e.target.value)
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    placeholder="Napomena za adresu"
                                    value={rtStartAddressNote}
                                    onChange={(e) =>
                                      setRtStartAddressNote(e.target.value)
                                    }
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <input
                                    type="text"
                                    placeholder="Krajnja adresa"
                                    value={rtEndAddress}
                                    onChange={(e) =>
                                      setRtEndAddress(e.target.value)
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    placeholder="Grad"
                                    value={rtEndCity}
                                    onChange={(e) =>
                                      setRtEndCity(e.target.value)
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    placeholder="Napomena za adresu"
                                    value={rtEndAddressNote}
                                    onChange={(e) =>
                                      setRtEndAddressNote(e.target.value)
                                    }
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                )}
              </Row>

              <div className="py-3 save-btn">
                <button onClick={() => setIsCdOpened(true)}>Sačuvaj</button>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default BookingEdit;
