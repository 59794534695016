export const statusOptions = ["Neobrađen", "Potvrđen", "Otkazan"];

export const travelLineoptions = [
  "Budimpešta-Beč",
  "Slovenija",
  "Grčka",
  "Zlatibor",
  "Kamp Vanje Grbića",
  "Kopaonik",
  "Vrnjačka Banja",
  "NAJMOVI",
  "Autobus - Beograd Budimpešta",
  "Istra",
  "Adria Summit",
];

export const travelType = ["OW One way", "RT Return"];

export const paymentType = [
  "Terra",
  "Bus",
  "Predračun",
  "Keš",
  "Kartica",
  "Gratis",
];

export const destinations = [
  "Budimpešta-Beč",
  "Slovenija",
  "Grčka",
  "Zlatibor",
  "Kamp Vanje Grbića",
  "Kopaonik",
  "Vrnjačka Banja",
  "NAJMOVI",
  "Autobus - Beograd Budimpešta",
  "Istra",
  "Adria Summit",
];

export const directions = [
  "Beograd - Beč",
  "Beograd - Slovenija",
  "Beograd - Grčka",
  "Beograd - Zlatibor",
  "Beograd (Arena) - Valjevo",
  "Beograd - Kopaonik",
  "Beograd - Vrnjačka Banja",
  "odlazak - polazak",
  "Beograd BAS - Budimpesta Nepliget",
  "Beograd - Istra",
  "Beograd - Umag",
];

export const directionsInverse = [
  "Beč - Beograd",
  "Slovenija - Beograd",
  "Grčka - Beograd",
  "Zlatibor - Beograd",
  "Valjevo - Beograd (Arena)",
  "Kopaonik - Beograd",
  "Vrnjačka Banja - Beograd",
  "polazak - odlazak",
  "Budimpesta Nepliget - Beograd BAS",
  "Istra - Beograd",
  "Umag - Beograd",
];

export const directionOptions = ["OW One way", "RT Return"];

export const subAgentOptions = [
  "",
  "Autoturist",
  "profis",
  "najam kombija",
  "Supernova Travel",
  "Holidayes",
  "Mouzenidis Travel",
];

export const timeOptions = [
  "neodređeno",
  "00:00-01:00",
  "01:00-02:00",
  "02:00-03:00",
  "03:00-04:00",
  "04:00-05:00",
  "05:00-06:00",
  "06:00-07:00",
  "07:00-08:00",
  "08:00-09:00",
  "09:00-10:00",
  "10:00-11:00",
  "11:00-12:00",
  "12:00-13:00",
  "13:00-14:00",
  "14:00-15:00",
  "15:00-16:00",
  "16:00-17:00",
  "17:00-18:00",
  "18:00-19:00",
  "19:00-20:00",
  "20:00-21:00",
  "21:00-22:00",
  "22:00-23:00",
  "23:00-00:00",
];

export const paymentMethodOptions = [
  "Terra",
  "Bus",
  "Predračun",
  "Keš",
  "Kartica",
  "Gratis",
];
