// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `th {
    font-size:medium;
}

select {
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    padding: 2px 6px;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/components/features/bookingList/BookingListTable.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;IACb,gBAAgB;AACpB","sourcesContent":["th {\r\n    font-size:medium;\r\n}\r\n\r\nselect {\r\n    border: 1px solid #ccc;\r\n    border-radius: 4px;\r\n    outline: none;\r\n    padding: 2px 6px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
